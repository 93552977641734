import React from 'react';

import { Dayjs } from 'dayjs';

import { ChantierAdminView } from '@travauxlib/shared/src/types/api/admin/ChantierAdminView';
import { ProjectView } from '@travauxlib/shared/src/types/api/admin/ProjectView';
import { AcquisitionMetadata } from '@travauxlib/shared/src/types/api/common/AcquisitionMetadata';
import { ConfigurationView } from '@travauxlib/shared/src/types/api/common/ConfigurationView';
import { DevisCommentView } from '@travauxlib/shared/src/types/api/common/DevisCommentView';
import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import {
  ProDevisFactureLotView,
  ProDevisFactureLigneView,
  TextItemView,
} from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { TypeLocation } from '@travauxlib/shared/src/types/api/common/TypeLocation';
import { ChiffreAffaires } from '@travauxlib/shared/src/types/api/domain/procompany/ChiffreAffaires';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';
import { ProDevisFacturePrime } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/ProDevisFacturePrime';
import { ProDevisFactureRemises } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/ProDevisFactureRemises';
import { ChantierProView } from '@travauxlib/shared/src/types/api/pro/ChantierProView';
import { DevisOuvrageView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

import { DocumentTag } from './api/domain/client/DocumentTag';
import { BaseDePrixIdentifier } from './baseDePrix';

import { OuvrageIdentifier } from '../features/Configurateur/utils/ouvrages';
import { Consent, ConsentValue } from '../utils/consents/constants';
import { FactureSoldeTotals } from '../utils/facture/getFactureSoldeTotals';
export type { TypeLocation } from '@travauxlib/shared/src/types/api/common/TypeLocation';
export { TypeBien } from '@travauxlib/shared/src/types/api/common/TypeBien';
export { TypeTravaux } from '@travauxlib/shared/src/types/api/common/TypeTravaux';
export { StadeAchat } from '@travauxlib/shared/src/types/api/common/StadeAchat';
export { Rooms } from '@travauxlib/shared/src/types/api/common/Rooms';
export { Dpe } from '@travauxlib/shared/src/types/api/common/Dpe';
export type { Location } from '@travauxlib/shared/src/types/api/common/Location';
export { UtilisationLogement } from '@travauxlib/shared/src/types/api/common/UtilisationLogement';
export { PersonRole } from '@travauxlib/shared/src/types/api/common/PersonView';
export type {
  LeadAssignmentConfig,
  PersonView as Person,
  PersonConfig,
} from '@travauxlib/shared/src/types/api/common/PersonView';

export type { DevisOuvrageView as Ouvrage } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
export type Environments = 'development' | 'staging' | 'production' | 'test';

export type ConsultationOfferConfiguration = {
  id: number;
};

export type DownloadableFile = {
  uuid: string;
  name: string;
  downloadUrl: string;
  key?: string;
  imgixUrl?: string;
  createdAt: string;
};

export type DownloadableFileWithTag = {
  tag: DocumentTag;
} & DownloadableFile;

export type ResolvedOuvrage = {
  uuid: string;
  designation: string;
  localisation?: string;
  devisItems: ProDevisFactureLigneView[];
  status?: LigneStatus;
  isRemainingOuvrages?: boolean;
  lotLabel?: string;
};

export enum DevisStatuses {
  draft = 'draft',
  sent = 'sent',
  signed = 'signed',
  rejected = 'rejected',
  canceled = 'canceled',
  to_be_revised = 'to_be_revised',
  sent_hemea = 'sent_hemea',
  rejected_hemea = 'rejected_hemea',
  to_be_revised_hemea = 'to_be_revised_hemea',
}

export enum FactureStatuses {
  draft = 'draft',
  awaiting_payment = 'awaiting_payment',
  paid = 'paid',
}

export type DevisFactureStatuses = DevisStatuses | FactureStatuses;

export type DevisCustomization = {
  devisExampleToken?: string;
  hasClosedBanner?: boolean;
  textColor?: string;
  logoSize?: string;
  titleFontStyle?: string;
  subTitleFontStyle?: string;
  bodyFontStyle?: string;
  headerElementsPosition?: string;
};

export type SenderType = 'pro' | 'client' | 'deal-owner';

export type SenderNames = { [K in SenderType]: string };

export type GlobalComment = Omit<DevisCommentView, 'targetUuid'>;

export type DevisComments = {
  [K: string]: DevisCommentView[];
};

export type DureeValidite = {
  quantity: number;
  unit: 'jour' | 'semaine' | 'mois';
};

// not very clean, typings should be improved
export type Devis = Omit<Prestations, 'replacedSignedDevisToken' | 'dateSignature'> & {
  date?: string;
  isFromConfiguration?: boolean;
  isPreChiffrage: boolean;
  sentAt?: string;
  status: DevisStatuses;
  version: number;
};

export type Facture = Prestations & {
  dateEmission?: string;
  dateFinPrestation?: string;
  delaiPaiement: number;
  introductionLetter?: string;
  status: FactureStatuses;
  viewCount?: number;
  proDevisToken?: string;
  proUserUuid?: string;
  isSituation: boolean;
  isCloture: boolean;
  previousFacturesAmount: number;
  avoirFromFactureToken?: string;
  linkedFactures: Pick<
    Facture,
    'lots' | 'prixTotalHT' | 'prixTotalTTC' | 'status' | 'montantsTVA'
  >[];
  paidAt?: string;
  compensationType?: CompensationType;
};

export type ProRegistration = {
  uuid: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  statutEntreprise: string;
  status: 'A traiter' | 'Adressable' | 'Lost';
  companyType: CompanyType;
  chiffreAffairesDeclare: ChiffreAffaires;
  deviceFirstPageView?: string;
  createdAt: string;
  metadata: AcquisitionMetadata;
  lostReason?: string;
  acquisitionLabel?: string;
  pipedriveDealUrl?: string;
  otherInterest?: string;
  isEmailVerified: boolean;
  proCompanySlug?: string;
  siret?: string;
  companyName?: string;
  numeroTVA?: string;
  rcs?: string;
  creationDate?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  capital?: number;
  firstNameRepresentantLegal?: string;
  lastNameRepresentantLegal?: string;
  proCompanyUuid: string;
  chiffreAffaires?: string;
  isLead?: boolean;
  calendly?: Meeting;
  legalState?: string;
  websites?: {
    personalWebsite?: string;
    linkedin?: string;
  };
  financials?: {
    lastSales?: number;
    lastStaff?: number;
  };
  internalComment?: string;
  activity?: CompanyActivity;
  interventionZone?: [];
  companyCreationDate?: string;
  isDemandeChantier: boolean;
};

export type ClientAccount = {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  optin?: boolean;
  phoneNumber?: string;
  passwordConfirmationToken?: string;
  createdAt: Dayjs;
  hemeaEmployee?: string;
  hasSeenOnboardingRevision: boolean;
};

export type BaseDePrixFournitureReference = {
  fournitureIdentifier: string;
  fabricantRef: string;
  designation: string;
  hemeaBrand: string;
  description?: string;
  prixHT: number;
  images: string[];
  url: string;
};

export type RevisedDevis = {
  lots: ProDevisFactureLotView[];
  locations: DevisLocation[];
};

export type GarbageCenter = {
  category: string;
  name: string;
  address: string;
  customCategory?: string;
};

export type Prestations = {
  archived?: boolean;
  chantierAddress?: string;
  createdAt: string;
  commissionRate?: number;
  customerName?: string;
  customerPhoneNumber?: string;
  dateSignature?: string;
  defaultMargin?: number;
  defaultTauxTVA: number;
  headerText?: TextItemView;
  highlightedUuids?: string[];
  isAvoir?: boolean;
  isHighlighted?: boolean;
  lastModifiedAt: string;
  locations: DevisLocation[];
  lots: ProDevisFactureLotView[];
  lotsByLocations?: ProDevisFactureLotView[];
  montantDejaPaye?: number;
  montantMargeTotale?: number;
  montantRemise: number;
  montantRestantAPayer?: number;
  montantsTVA: MontantTVA[];
  numero?: string;
  ouvrages?: DevisOuvrageView[];
  primes: ProDevisFacturePrime[];
  prixTotalMainOeuvreHT?: number;
  prixTotalFournitureHT?: number;
  prixTotalFreeTTC?: number;
  prixTotalHT: number;
  prixTotalHTAvantRemise: number;
  prixTotalTTC: number;
  prixTotalTTCAvantPrimes: number;
  prixTotalTTCAvantRemise?: number;
  remises: ProDevisFactureRemises;
  replacedSignedDevisToken?: string;
  showFournitures: boolean;
  showMainOeuvre?: boolean;
  title: string;
  token: string;
  transferred?: boolean;
  globalComment?: GlobalComment;
  sentAt?: string;
};

export type UnitValue = 'unitaire' | 'm' | 'm2' | 'm3' | 'ml' | 'h' | 'j' | 'kg' | 'forfait';
export type UnitLabel = 'unité' | 'm' | 'm²' | 'm³' | 'ml' | 'heure' | 'jour' | 'kg' | 'forfait';

export type Unit = {
  label: UnitLabel;
  value: UnitValue;
  priceUnit: string;
  unitaire?: boolean;
  shortLabel?: string;
};

export type OriginType = 'batiprix' | 'self' | 'hemea';

export type HemeaItem = {
  id?: undefined;
  normalizedIdentifier: string;
  quantity: number;
};

export type CustomItem = {
  id: number;
  normalizedIdentifier?: undefined;
  quantity: number;
};

export type BaseDePrixItem = HemeaItem | CustomItem;

export type BaseDePrixLigne = {
  id?: number;
  normalizedIdentifier?: BaseDePrixIdentifier;
  lot: string;
  category?: string;
  categoryLabel?: OuvrageIdentifier;
  designation: string;
  normalizedForSearchDesignation?: string;
  description?: string;
  prixUnitaireMainOeuvreHT?: number;
  prixPublicFournitureHT?: number;
  fournitureName?: string;
  prixPrimoFournitureHT?: number;
  prixConfortFournitureHT?: number;
  prixPremiumFournitureHT?: number;
  prixExclusifFournitureHT?: number;
  prixUnitaireHT: number;
  prixAchat?: number;
  unite: UnitValue;
  uniteLabel?: string;
  tauxTVA: number;
  quantityComputerId?: QuantityComputerId;
  defaultLocation?: DefaultLocation;
  priority?: number;
  hemeaRecommandations?: BaseDePrixFournitureReference[];
  proUserUuid?: string;
  lastModifiedAt?: string;
  favorite?: boolean;
  origin: OriginType;
  mainOeuvreIdentifier?: string;
  mainOeuvreId?: number;
  mainOeuvres?: BaseDePrixItem[];
  resolvedMainOeuvres?: [MainOeuvre, number][];
  fournitures?: BaseDePrixItem[];
  resolvedFournitures?: [BaseDePrixFourniture, number][];
  materiaux?: BaseDePrixItem[];
  resolvedMateriaux?: [Materiau, number][];
};

export type MainOeuvre = {
  id?: number;
  normalizedIdentifier?: string;
  designation: string;
  prixAchat: number;
  prixAchatJournee: number;
  marge: number;
};

export type Materiau = {
  id?: number;
  normalizedIdentifier?: string;
  designation: string;
  description?: string;
  prixAchat: number;
  decoupe?: number;
  marge: number;
  unite: UnitValue;
};

export type BaseDePrixFourniture = {
  id?: number;
  normalizedIdentifier?: string;
  designation: string;
  description?: string;
  prixAchat: number;
  decoupe?: number;
  unite: UnitValue;
};

export type MontantTVA = {
  taux: number;
  base: number;
  montant: number;
};

export type LigneCategory = {
  label: string;
  lignes: BaseDePrixLigne[];
};

export type QuantityComputerId =
  | 'sols'
  | 'murs'
  | 'perimetre'
  | 'entree'
  | 'cuisine'
  | 'salle_de_bain'
  | 'wc'
  | 'cloison'
  | 'non_applicable';

export type DefaultLocation = 'entree' | 'cuisine' | 'salle_de_bain' | 'wc' | 'au_global';

export enum LigneStatus {
  Option = 'option',
  Free = 'free',
  ToEdit = 'to_edit',
  Modified = 'modified',
  Canceled = 'canceled',
}

export type FournitureLigne = {
  uuid: string;
  url?: string;
  images: string[];
  designation?: string;
  description?: string;
  prixHT: number;
  hemeaBrand?: string;
};

export type ComparisonStatus = 'added' | 'missing' | 'unchanged' | 'modified';

export type LigneLocation = {
  uuid: string;
  quantite: number;
};

export type AllTypeLocation = 'WithoutLocation' | TypeLocation;

export type Attachment = {
  file: File;
  fileUrl: string;
  imageUrl: string;
  id: string;
  label: string;
};

export type ConsentPayload = { [K in Consent]: ConsentValue };
export type GrantedConsents = {
  isAnalyticsConsentGranted: boolean;
  isAdConsentGranted: boolean;
  isPersonalizationConsentGranted: boolean;
};

export type BonCommandeLigne = {
  uuid?: string;
  designation: string;
  description?: string;
  normalizedIdentifier?: string;
  proBaseDePrixId?: string;
  quantite: number;
  unite: UnitValue;
  prixUnitaireHT: number;
  locations: LigneLocation[];
  marge?: number;
  selected: boolean;
};

export type BonCommandeLot = {
  uuid: string;
  label: string;
  prixTotalHT?: number;
  lignes: BonCommandeLigne[];
};

export type Contractor = {
  uuid: string;
  updatedAt: string;
  name: string;
  phoneNumber?: string;
  email?: string;
  billingFormattedAddress?: string;
  billingGoogleMapsAddress?: GoogleMapsAddress;
  amountHT: number;
  siret?: string;
  fullname?: string;
  capital?: number;
  certificationsRGE?: DownloadableFile[];
};

export type DevisContractor = {
  uuid: string;
  name: string;
  billingFormattedAddress?: string;
  phoneNumber?: string;
  siret?: string;
  certificationsRGE?: DownloadableFile[];
  lots: string[];
};

export type MediateurConsommation = {
  uuid: string;
  proCompanyUuid: string;
  googleMapsAddress: GoogleMapsAddress;
  fullName: string;
  phoneNumber: string;
  website?: string;
};

export type BonCommande = {
  uuid: string;
  token: string;
  proDevisToken: string;
  dealUuid: string;
  proCompanyUuid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  sentAt?: string;
  signatureDate?: string;
  lots: BonCommandeLot[];
  locations: DevisLocation[];
  status: DevisStatuses;
  prixTotalHT: number;
  montantsTVA: MontantTVA[];
  marge: number;
  archived: boolean;
  contractorUuid: string;
};

export type Meeting = {
  uuid: string;
  invitee: string;
  inviter: string;
  startAt: string;
  createdAt: string;
  updatedAt: string;
  canceledAt?: string;
  hasOccurred: boolean;
  calendlyRescheduleUrl?: string;
  calendlyCancelUrl?: string;
};

export type SuiviLigneLegacy = {
  uuid: string;
  designation: string;
  description?: string;
  completionPercentage: number;
  normalizedIdentifier?: string;
  proBaseDePrixId?: string;
  comment: string;
  prixUnitaireHT: number;
  quantite: number;
  unite: UnitValue;
  locations: LigneLocation[];
};

export type SuiviLot = {
  uuid: string;
  label: string;
  lignes: SuiviLigneLegacy[];
};

export type SuiviChantier = {
  uuid: string;
  token: string;
  proDevisToken: string;
  clientUuid: string;
  dealUuid: string;
  proCompanyUuid: string;
  title: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  lots: SuiviLot[];
  locations: DevisLocation[];
};

type FileWithMetadata = {
  uuid: string;
  name: string;
  type: string;
  body: File;
};

export type UploadFilesPayload = {
  projectUuid?: string;
  files: FileWithMetadata[];
  onUpdate: (progressEvent: ProgressEvent, fileIndex: number) => void;
  onSuccess: (fileIndex: number) => void;
  onError: (fileIndex: number) => void;
};

export type InsurancePayload = {
  dateDebutValidite?: string;
  dateFinValidite?: string;
  file: DownloadableFile;
  label: string;
  slug?: string;
};

export type CompanyActivity =
  | 'grosOeuvre'
  | 'renovationInterieure'
  | 'specialiste'
  | 'archiInterieur'
  | 'archiHMONP'
  | 'bureauEtudes';

export const optionActivity: {
  value: CompanyActivity;
  label: string;
}[] = [
  {
    value: 'grosOeuvre',
    label: 'Gros œuvre',
  },
  {
    value: 'renovationInterieure',
    label: 'Rénovation intérieure',
  },
  {
    value: 'specialiste',
    label: 'Spécialiste',
  },
  {
    value: 'archiInterieur',
    label: "Architecte d'intérieur",
  },
  {
    value: 'archiHMONP',
    label: 'Architecte HMONP',
  },
  {
    value: 'bureauEtudes',
    label: "Bureau d'études techniques",
  },
];

export type DevisFacturationStatus = {
  totalMontantDejaPayeTTC: number;
  totalDejaCreeHT: number;
  totalDejaFactureHT: number;
  totalDejaFactureTTC: number;
  percentageDejaPayeTTC: number;
  totalMontantEnAttentePaiementTTC: number;
  percentageEnAttentePaiementTTC: number;
  totalMontantRestantAFacturerTTC: number;
  percentageRestantAFacturerTTC: number;
  referenceAmountTTC: number;
  totalMontantAvoirHT: number;
  totalMontantAvoirTTC: number;
  totalFactureSoldeTTC: number;
  isClotured: boolean;
  hasCloture: boolean;
  factureSoldeTotals?: FactureSoldeTotals;
};

export type GenericFacture = {
  isAvoir?: boolean;
  prixTotalTTC: number;
  prixTotalHT: number;
  status: FactureStatuses;
  lots: ProDevisFactureLotView[];
  montantsTVA: MontantTVA[];
  isCloture?: boolean;
  isSituation?: boolean;
};

export enum SuiviLigneStatus {
  Agreed = 'agreed',
  Rejected = 'rejected',
  Pending = 'pending',
  Disputed = 'disputed',
}

export enum ClientActionType {
  TakeRdv = 'takeRdv',
  AddDpe = 'addDpe',
  AddPlans = 'addPlans',
  AddPhotos = 'addPhotos',
  SeeGuides = 'seeGuides',
  AddInspirations = 'addInspirations',
  SeeArchiDevis = 'seeArchiDevis',
  SeeProDevis = 'seeProDevis',
  SeeArchiDevisTs = 'seeArchiDevisTs',
  CompleteFournitures = 'completeFournitures',
  SeeProDevisTs = 'seeProDevisTs',
  AddProvisions = 'addProvisions',
  SeeSuiviChantier = 'seeSuiviChantier',
  ValidateReceptionChantier = 'validateReceptionChantier',
  ValidateLeveeReserves = 'validateLeveeReserves',
  ValidateInitialAdp = 'validateInitialAdp',
}

export enum ActionStatus {
  Available = 'available',
  Skipped = 'skipped',
  Validated = 'validated',
}

export type ClientAction = {
  title: string;
  secondaryTitle?: string;
  iconName: string;
  to: string;
  actionStatus: ActionStatus;
  actionType: ClientActionType;
  entityIdentifier?: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  validatedAt?: string;
};

export type DocumentConfig = {
  label: string;
  icon: React.ReactNode;
  description: string | React.ReactNode;
};

export type CompensationType = 'penalties' | 'compensation';

export type SlotRDV = {
  start: Date;
  end: Date;
  type: 'booked' | 'available';
  label?: string;
  isCalendly?: boolean;
};

// export type Project = {
//   uuid: string;
//   files: DownloadableFileWithTag[];
//   pipedriveDealUrl?: string;
//   pipedriveDealId?: number;
//   etape?: ProjectStep;
//   title: string;
//   bookedSlots: SlotRendezVousView[];
//   slotsRendezVous: SlotRendezVousView[];
//   chunkedSlotsRendezVous: SlotRendezVousView[];
//   description?: string;
//   minutesBetweenRdv: number;
//   enableConformiteDevis: boolean;
//   leadId?: string;
// } & TypologieProject;

// export type TypologieProject = {
//   typeLogement?: TypeBien;
//   rooms?: Rooms;
//   floor?: number;
//   floorCount?: number;
//   hasElevator?: boolean;
//   mainGamme: Gamme;
//   locations: Location[];
//   hauteurSousPlafond?: number;
//   address?: GoogleMapsAddress;
//   stadeAchat?: StadeAchat;
//   utilisationLogement?: UtilisationLogement;
//   dpe?: Dpe;
//   debutTravaux?: string;
//   typeTravaux?: TypeTravaux;
//   isRenovationEnergetique?: boolean;
//   budgetTravaux?: number;
//   surfaceTotale?: number;
//   comments: string;
//   prixHT?: number;
// };

export type FournitureState = 'draft' | 'complete' | 'editing';

export type Fourniture = {
  uuid: string;
  normalizedIdentifier?: string;
  designation: string;
  reference?: FournitureReference;
  createdAt?: string;
};

export type FournitureReference = {
  uuid: string;
  designation: string;
  description?: string;
  fournisseur: string;
  referenceNumber: string;
  referenceLink?: string;
  images: DownloadableFile[];
  mainImageUuid?: string;
  isRecommandationHemea: boolean;
};

export type FournitureReferenceScrapped = Partial<
  Pick<FournitureReference, 'designation' | 'description' | 'images'>
>;

export type ProCompanyFournituresView = {
  proCompanyUuid: string;
  proCompanyName: string;
  isArchitecte: boolean;
  fournitures: FournituresWithLocation[];
};

export type FournituresWithLocation = {
  locationName: string;
  locationType: AllTypeLocation;
  fournitures: Fourniture[];
};

// to be killed soon
export type ProUser = {
  id?: number;
  uuid?: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  isProCompanyAdmin: boolean;
  archived?: boolean;
  firstName?: string;
  lastName?: string;
};

export type HybridChantier = ChantierAdminView | ChantierProView;

export type TypologieProject = ProjectView | ConfigurationView;
